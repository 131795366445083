'use client'

import * as React from 'react'
import { useRouter } from 'next/navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faX, faCamera, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'
import { sendGTMEvent } from '@next/third-parties/google'
import { useDebouncedCallback } from 'use-debounce'
import { useAppSelector } from '@/lib/store'
import { getCurrentUserIsLoggedIn, getCurrentUser } from '@/lib/features/currentUser/currentUserSlice'
import { getToken } from '@/lib/features/token/tokenSlice'
import Helper from '@/app/(client-layout)/components/Text-prompt-helper/text-prompt-helper'
import Tokenizer from '@/lib/clip-tokenizer'
import { MAX_USER_PROMPT_TOKENS } from '../../../../../../../../lib/constants/lib'
import { clientApiService } from '@/services/client-api-service'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const tokenizer = new Tokenizer()

export default ((): React.ReactElement => {
  const router = useRouter()
  const token = useAppSelector(getToken)
  const user = useAppSelector(getCurrentUser)

  const userIsLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)

  const [isGenerating, setIsGenerating] = React.useState<boolean>(false)
  const [userPrompt, setUserPrompt] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [isLimitReachedModalOpen, setIsLimitReachedModalOpen] = React.useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [isModalHelperOpen, setIsModalHelperOpen] = React.useState<boolean>(false)
  const [numPromptTokens, setNumPromptTokens] = React.useState<number>(0)

  const openModal = (): void => {
    setIsModalOpen(true)
  }

  const openHelperModal = (): void => {
    setIsModalHelperOpen(true)
  }

  const closeModal = (): void => {
    setIsModalOpen(false)
    setIsModalHelperOpen(false)
  }

  React.useEffect(() => {
    const { body } = document

    if (isModalHelperOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'auto'
    }

    return () => {
      body.style.overflow = 'auto'
    }
  }, [isModalHelperOpen])

  const setNumPromptTokensWithDebounce = useDebouncedCallback((rawUserPrompt: string): void => {
    setNumPromptTokens(tokenizer.encode(rawUserPrompt).length)
  }, 300)

  const handleGenerate = (): void => {
    if (!userPrompt) {
      setErrorMessage('Your prompt cannot be empty')

      return
    }

    if (userPrompt.length < 3) {
      setErrorMessage('Your prompt must be at least 3 characters')

      return
    }

    if (userPrompt.match(/[^\x20-\x7F]/)) {
      setErrorMessage('Your prompt must contain only English letters and symbols')

      return
    }

    if (tokenizer.encode(userPrompt).length > MAX_USER_PROMPT_TOKENS) {
      setErrorMessage(`Your prompt is longer than the ${MAX_USER_PROMPT_TOKENS} token limit`)

      return
    }

    if (user?.user_membership ?? userIsLoggedIn) {
      localStorage.setItem('userPrompt', userPrompt)
      router.push('/generate')

      return
    }

    void (async () => {
      setIsGenerating(true)
      setErrorMessage('')

      const { body, status } = await clientApiService.generateImage.mutation({
        body: {
          userPrompt,
          userNegativePrompt: '',
        },
        extraHeaders: {
          authorization: token ? `Bearer ${token}` : undefined,
        },
      })

      setIsGenerating(false)

      if (status !== 200) {
        const limitReachedMessages = ['Not enough credits remaining', 'Not enough free credits remaining for today']

        if (body.message && limitReachedMessages.includes(body.message)) {
          setIsLimitReachedModalOpen(true)
        } else {
          setErrorMessage(body.message)
        }

        return
      }

      if (process.env['NEXT_PUBLIC_SVGIO_STAGE'] === 'prod') {
        sendGTMEvent({
          event: 'image_generate',
          user_id: user?.id,
          image_id: body.data?.map((img) => img.id).join(','),
        })
      }

      if (!userIsLoggedIn) {
        const { body: creditsLeftBody, status: creditsLeftStatus } = await clientApiService.getNonMemberCreditsLeftToday.query({
          extraHeaders: {
            authorization: token ? `Bearer ${token}` : undefined,
          },
        })

        if (creditsLeftStatus === 200 && creditsLeftBody.data.numCreditsLeftToday === 0) {
          if (process.env['NEXT_PUBLIC_SVGIO_STAGE'] === 'prod') {
            sendGTMEvent({
              event: 'used_all_daily_free_credits',
              user_id: user?.id,
            })
          }
        }
      } else if (user && !user.user_membership && user.user_credits?.membership_credits === 0) {
        if (process.env['NEXT_PUBLIC_SVGIO_STAGE'] === 'prod') {
          sendGTMEvent({
            event: 'used_all_daily_free_credits',
            user_id: user.id,
          })
        }
      }

      router.push(`/${userIsLoggedIn ? 'generate' : 'download'}/${body.data?.[0]?.id}`)
    })()
  }

  return (
    <div className="container mx-auto grid place-items-center px-4 pt-10 md:px-6 lg:px-8 xl:px-0">
      <form className="flex w-full items-center sm:w-full md:w-4/5 lg:w-1/2 xl:w-2/5">
        <div className="relative w-full">
          <div className="relative">
            <input
              type="text"
              value={userPrompt}
              placeholder="Type your idea in English here..."
              className={`border-1 border-default focus:border-primary w-full rounded border p-6 pl-20 focus:outline-none md:mb-0 ${isGenerating ? 'md:pr-56' : 'md:pr-40'}`}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (['Enter', 'NumpadEnter'].includes(event.code)) {
                  event.preventDefault()

                  handleGenerate()
                }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUserPrompt(event.target.value)
                setNumPromptTokensWithDebounce(event.target.value)
              }}
            />

            <div className="border-default absolute -top-0 right-1 -translate-y-3.5">
              {numPromptTokens <= MAX_USER_PROMPT_TOKENS ? (
                <span className="me-2 rounded bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 dark:bg-green-900 dark:text-green-300">
                  {numPromptTokens} / {MAX_USER_PROMPT_TOKENS}
                </span>
              ) : (
                <span className="me-2 rounded bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800 dark:bg-red-900 dark:text-red-300">
                  {numPromptTokens} / {MAX_USER_PROMPT_TOKENS}
                </span>
              )}
            </div>

            <div className="border-default absolute left-0 top-1/2 h-full w-14 -translate-y-1/2 border-r">
              <div className="relative size-full">
                {user?.user_membership ? (
                  <Link href="/generate" className="absolute left-0 size-full">
                    <FontAwesomeIcon
                      icon={faCamera}
                      className="text-default absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2"
                      width={24}
                      height={24}
                      aria-label="Upload"
                    />
                  </Link>
                ) : (
                  <button type="button" onClick={openModal} className="absolute left-0 size-full">
                    <FontAwesomeIcon
                      icon={faCamera}
                      className="text-default absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2"
                      width={24}
                      height={24}
                      aria-label="Upload"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          {isGenerating ? (
            <button
              type="button"
              disabled
              className="bg-primary mt-6 flex w-full items-center justify-center rounded px-6 py-4 text-white opacity-50 transition-all duration-300 ease-in-out md:absolute md:right-3 md:top-1/2 md:mt-0 md:w-auto md:-translate-y-1/2 md:px-6 md:py-2"
            >
              <svg
                aria-hidden="true"
                role="status"
                className="me-3 inline size-5 animate-spin text-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Generating...
            </button>
          ) : (
            <button
              type="button"
              className="bg-primary hover:bg-secondary mt-6 w-full rounded px-6 py-4 text-white transition-all duration-300 ease-in-out md:absolute md:right-3 md:top-1/2 md:mt-0 md:w-auto md:-translate-y-1/2 md:px-6 md:py-2"
              onClick={handleGenerate}
            >
              Generate
            </button>
          )}
        </div>
      </form>
      <div className="mt-4">
        <button type="button" onClick={openHelperModal} aria-label="Tips" className="text-primary text-sm">
          Tips to improve your image generation
        </button>
      </div>
      {errorMessage && (
        <div className="mt-6 flex items-center rounded border border-red-400 bg-red-100 px-4 py-1.5" role="alert">
          <span className="mr-2">
            <FontAwesomeIcon icon={faCircleExclamation} className="size-4 text-red-700" />
          </span>
          <p className="text-sm text-red-700">{errorMessage}</p>
        </div>
      )}

      {isLimitReachedModalOpen && (
        <div className="fixed left-0 top-0 z-30 flex size-full items-center justify-center bg-white/90 backdrop-blur-sm">
          <div className="relative max-w-sm rounded-lg bg-white p-8 shadow-md xl:max-w-lg">
            <h3 className="mb-4 font-bold uppercase">
              <FontAwesomeIcon icon={faStar} className="text-premium mr-2 size-5" /> Not Enough Free Credits Remaining
            </h3>
            <p className="mb-6">
              If you are interested in generating more images and have access to our premium styles, we invite you to upgrade your account.
            </p>
            <Link
              href="/pricing"
              className="bg-primary hover:bg-secondary inline-block rounded px-6 py-2 text-white transition-all duration-300 ease-in-out"
            >
              Get access
            </Link>
            <button
              type="button"
              aria-label="premium style"
              className="absolute right-4 top-3"
              onClick={() => {
                setIsLimitReachedModalOpen(false)
              }}
            >
              <FontAwesomeIcon icon={faX} className="text-default size-3" />
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed left-0 top-0 z-30 flex size-full items-center justify-center bg-white/90 backdrop-blur-sm">
          <div className="relative max-w-sm rounded-lg bg-white text-left shadow-md xl:max-w-lg">
            <div className="relative px-8 py-6">
              <h3 className="font-bold uppercase">
                <FontAwesomeIcon icon={faStar} className="text-premium size-4.5 mr-2" /> Photo + Text Prompts
              </h3>
              <button type="button" aria-label="Close" className="absolute right-4 top-1/2 -translate-y-1/2" onClick={closeModal}>
                <FontAwesomeIcon icon={faX} className="text-default size-4" />
              </button>
            </div>
            <div className="p-8 pt-2">
              <p className="mb-6">
                Unlock exclusive option! Seamlessly merge your photos with text prompts. We invite you to upgrade your account.
              </p>
              <Link
                href={userIsLoggedIn ? '/plan' : '/pricing'}
                className="bg-primary hover:bg-secondary inline-block rounded px-6 py-2 text-white transition-all duration-300 ease-in-out"
              >
                Get access
              </Link>
            </div>
          </div>
        </div>
      )}

      {isModalHelperOpen && (
        <div className="fixed left-0 top-0 z-30 flex size-full items-center justify-center bg-white/90 backdrop-blur-sm">
          <div className="relative w-5/6 rounded-lg bg-white text-left shadow-md xl:w-1/2">
            <div className="relative px-8 py-6">
              <h2 className="sm:leading-1 font-bold uppercase leading-tight">
                Tips to improve <br className="block sm:hidden" />
                your text prompts
              </h2>
              <button type="button" aria-label="Close" className="absolute right-4 top-1/2 -translate-y-1/2" onClick={closeModal}>
                <FontAwesomeIcon icon={faX} className="text-default size-4" />
              </button>
            </div>
            <div className="max-h-132 overflow-y-auto p-8 pt-0">
              <Helper />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}) satisfies React.FC<Props>
