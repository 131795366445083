'use client'

import * as React from 'react'
import Generator from '../components/Generator'
import PromptExamples from '../components/prompts-examples'
import PromptsAndImageExamples from '../components/prompts-and-image-examples'
import Faq from '../components/Faq/faq'
import Cta from '../components/Cta/cta'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  React.useEffect(() => {
    const handleSmoothScroll = (): void => {
      const { hash } = window.location

      if (hash) {
        const element = document.querySelector(hash)

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    handleSmoothScroll()

    // Smooth scroll on hash change
    window.addEventListener('hashchange', handleSmoothScroll)

    return () => {
      window.removeEventListener('hashchange', handleSmoothScroll)
    }
  }, [])

  return (
    <>
      <div className="bg-light mx-auto grid place-items-center px-4 py-16 text-center md:px-0">
        <h1 className="text-primary mb-4 text-2xl font-bold sm:text-2xl md:text-2xl lg:text-3xl xl:text-3xl">
          Text prompt to SVG in less than 10 seconds
        </h1>
        <p className="mb-4 md:mb-0">Discover the power of AI with our free Text-to-SVG generator!</p>
        <p>Convert your text prompts into stunning SVG illustrations using our advanced AI technology.</p>
        <Generator />
      </div>
      <div className="container mx-auto grid">
        <PromptExamples />
      </div>
      <Cta />
      <PromptsAndImageExamples />
      <Faq />
    </>
  )
}) satisfies React.FC<Props>
