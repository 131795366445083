'use client'

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faX } from '@fortawesome/free-solid-svg-icons'
import Image from 'next/image'
import Link from 'next/link'
import { useAppSelector } from '@/lib/store'
import { getCurrentUserIsLoggedIn, getCurrentUser } from '@/lib/features/currentUser/currentUserSlice'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  const userIsLoggedIn = useAppSelector(getCurrentUserIsLoggedIn)
  const user = useAppSelector(getCurrentUser)

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [modalStyle, setModalStyle] = React.useState<string>('')

  const openModal = React.useCallback((styleName: string) => {
    setModalStyle(styleName)
    setModalOpen(true)
  }, [])

  const closeModal = React.useCallback(() => {
    setModalOpen(false)
  }, [])

  const exampleList = [
    {
      image: '/images/examples/flat_witch-and-cauldron.svg',
      isPremium: false,
      styleName: 'Flat',
    },
    {
      image: '/images/examples/outline_flower-pot.svg',
      isPremium: true,
      styleName: 'Outline art',
    },
    {
      image: '/images/examples/onelineart_dog.svg',
      isPremium: true,
      styleName: 'One line art',
    },
    {
      image: '/images/examples/flat_skull-face.svg',
      isPremium: false,
      styleName: 'Flat',
    },
    {
      image: '/images/examples/silhouette_deer.svg',
      isPremium: true,
      styleName: 'Silhouette',
    },
    {
      image: '/images/examples/flat_lion.svg',
      isPremium: false,
      styleName: 'Flat',
    },
    {
      image: '/images/examples/onelineart_fairy.svg',
      isPremium: true,
      styleName: 'One line art',
    },

    {
      image: '/images/examples/flat_fox-surrounded-by-stars.svg',
      isPremium: false,
      styleName: 'Flat',
    },
    {
      image: '/images/examples/outline_pumpkin.svg',
      isPremium: true,
      styleName: 'Outline art',
    },
    {
      image: '/images/examples/lineart_girl-reading-book.svg',
      isPremium: true,
      styleName: 'Line art',
    },
    {
      image: '/images/examples/flat_llama-face.svg',
      isPremium: false,
      styleName: 'Flat',
    },
    {
      image: '/images/examples/flat_vintage-car.svg',
      isPremium: false,
      styleName: 'Flat',
    },
  ]

  return (
    <div className="container mx-auto my-20 px-4 md:px-6 lg:px-8 xl:px-4">
      <h2 className="mb-6 text-center text-2xl font-bold md:text-xl lg:mb-8 lg:text-2xl xl:mb-12 xl:text-3xl">
        Examples of what you can generate with our different <span className="text-primary">styles</span>
      </h2>
      <ul className="grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-6 lg:grid-cols-4 xl:grid-cols-6 xl:gap-8">
        {exampleList.map((example, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`example_${example.image}_${index}`} className="border-light relative rounded border p-5 shadow-lg">
            <Image src={example.image} alt={example.styleName} className="mx-auto" width="182" height="182" />
            {example.isPremium && (
              <button
                type="button"
                aria-label="free style"
                className="absolute right-3 top-3"
                onClick={() => {
                  openModal(example.styleName)
                }}
                disabled={!!user?.user_membership}
              >
                <FontAwesomeIcon icon={faStar} className="text-premium size-5" />
              </button>
            )}
          </li>
        ))}
      </ul>

      {modalOpen && (
        <div className="fixed left-0 top-0 z-10 flex size-full items-center justify-center bg-white/90 backdrop-blur-sm">
          <div className="relative max-w-sm rounded-lg bg-white shadow-md xl:max-w-lg">
            <div className="relative px-8 py-6">
              <h3 className="font-bold uppercase">
                <FontAwesomeIcon icon={faStar} className="text-premium size-4.5 mr-2" /> Exclusive style
              </h3>
              <button type="button" aria-label="Close" className="absolute right-4 top-1/2 -translate-y-1/2" onClick={closeModal}>
                <FontAwesomeIcon icon={faX} className="text-default size-4" />
              </button>
            </div>
            <div className="p-8 pt-2">
              <p className="mb-6">
                If you are interested in enjoying this <b className="bfont-semibold uppercase">{modalStyle}</b> style, we invite you to
                upgrade your account.
              </p>
              <Link
                href={userIsLoggedIn ? '/plan' : '/pricing'}
                className="bg-primary hover:bg-secondary inline-block rounded px-6 py-2 text-white transition-all duration-300 ease-in-out"
              >
                Get access
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}) satisfies React.FC<Props>
