'use client'

import * as React from 'react'
import Image from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  return (
    <div className="border-light border-b">
      <div className="container mx-auto my-20 px-4 md:px-6 lg:px-8 xl:px-4">
        <h2 className="mb-2 text-center text-2xl font-bold md:text-xl lg:mb-8 lg:text-2xl xl:mb-2 xl:text-3xl">
          Discover the power of combining <span className="text-primary">images and text prompts</span>
        </h2>
        <div className="mx-auto mb-12 max-w-5xl text-center">
          <p>
            Witness the magic unfold as these two elements blend to generate unique SVG outputs. Simply input your desired image and text
            prompts, and watch as our tool crafts imaginative creations for you.
          </p>
        </div>
        <div className="mx-auto mb-6 xl:flex xl:max-w-7xl xl:justify-center xl:space-x-12">
          <div className="items-top bg-light relative mx-auto mb-12 w-full rounded-2xl px-6 pb-12 pt-6 md:flex md:w-4/5 md:justify-center md:p-10 lg:w-3/4 xl:mb-0 xl:w-1/2">
            <span className="text-primary mb-6 block rounded text-center text-xl font-semibold md:absolute md:right-12 md:top-6 md:mb-0 md:text-2xl xl:text-xl">
              Be faithful
            </span>
            <div>
              <p className="mb-3 text-center text-sm font-bold">Upload image + Text prompt</p>
              <div className="relative rounded bg-white px-4 py-6">
                <div className="border-light mx-auto max-w-56 rounded border-2 border-dashed bg-white p-2 xl:max-w-full">
                  <Image src="/images/examples/dalmatian.jpg" alt="Upload image" width="240" height="240" />
                </div>
                <div className="my-3 flex items-center justify-center">
                  <FontAwesomeIcon icon={faPlus} className="text-default size-3" />
                </div>
                <div className="flex items-center justify-center">
                  <div className="border-light w-full whitespace-nowrap rounded border bg-white px-4 py-3 text-sm">
                    <p className="text-center text-sm">Dogs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-0 mt-6 md:ml-12 md:mt-16">
              <Image src="/images/arrow.svg" className="mx-auto hidden md:ml-0 md:block" width={60} height={30} alt="Arrow down" />
              <Image
                src="/images/arrow-mobile.svg"
                className="mx-auto mb-4 block md:ml-0 md:hidden"
                width={40}
                height={20}
                alt="Arrow down"
              />
              <div className="mx-auto max-w-56 md:max-w-full">
                <p className="mb-3 text-sm font-bold">Combined result</p>
                <div className="border-light relative mx-auto max-w-full rounded border bg-white p-5 shadow-lg md:max-w-60 xl:max-w-full">
                  <Image src="/images/examples/dalmatian.svg" alt="Combined result" width="240" height="240" />
                  <span className="bg-default absolute right-[-10px] top-[-12px] rounded px-2 py-1 text-xs font-semibold tracking-wider text-white">
                    SVG
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="items-top bg-light relative mx-auto mb-12 w-full rounded-2xl px-6 pb-12 pt-6 md:flex md:w-4/5 md:justify-center md:p-10 lg:w-3/4 xl:mb-0 xl:w-1/2">
            <span className="text-primary mb-6 block rounded text-center text-xl font-semibold md:absolute md:right-12 md:top-6 md:mb-0 md:text-2xl xl:text-xl">
              Be creative
            </span>
            <div>
              <p className="mb-3 text-center text-sm font-bold">Upload image + Text prompt</p>
              <div className="relative rounded bg-white px-4 py-6">
                <div className="border-light mx-auto max-w-56 rounded border-2 border-dashed bg-white p-2 xl:max-w-full">
                  <Image src="/images/examples/man.jpg" alt="Upload image" width="240" height="240" />
                </div>
                <div className="my-3 flex items-center justify-center">
                  <FontAwesomeIcon icon={faPlus} className="text-default size-3" />
                </div>
                <div className="flex items-center justify-center">
                  <div className="border-light w-full whitespace-nowrap rounded border bg-white px-4 py-3 text-sm">
                    <p className="text-center text-sm">Astronaut</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-0 mt-6 md:ml-12 md:mt-16">
              <Image src="/images/arrow.svg" className="mx-auto hidden md:ml-0 md:block" width={60} height={30} alt="Arrow down" />
              <Image
                src="/images/arrow-mobile.svg"
                className="mx-auto mb-4 block md:ml-0 md:hidden"
                width={40}
                height={20}
                alt="Arrow down"
              />
              <div className="mx-auto max-w-56 md:max-w-full">
                <p className="mb-3 text-sm font-bold">Combined result</p>
                <div className="border-light relative mx-auto max-w-full rounded border bg-white p-5 shadow-lg md:max-w-60 xl:max-w-full">
                  <Image src="/images/examples/man-to-astronaut.svg" alt="Combined result" width="240" height="240" />
                  <span className="bg-default absolute right-[-10px] top-[-12px] rounded px-2 py-1 text-xs font-semibold tracking-wider text-white">
                    SVG
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}) satisfies React.FC<Props>
